import { http } from '@/http/axios.js'
// list
export function getListAPI(params) {
  return http({
    url: '/admin/channel/page',
    method: 'get',
    params
  })
}
// delete
export function deleteChannelAPI(id) {
  return http({
    url: '/admin/channel/delete',
    method: 'post',
    data: { id }
  })
}
// add
export function addChannelAPI(data) {
  return http({
    url: '/admin/channel/add',
    method: 'post',
    data
  })
}
// edit
export function editChannelAPI(data) {
  return http({
    url: '/admin/channel/edit',
    method: 'post',
    data
  })
}
// detail
export function getChannelDetailAPI(id) {
  return http({
    url: '/admin/channel/detail',
    method: 'get',
    params: { id }
  })
}
// status
export function changeStatusAPI(data) {
  return http({
    url: '/admin/channel/status',
    method: 'post',
    data
  })
}
export function changeAlipayFundAuthAPI(data) {
  return http({
    url: '/admin/channel/alipayFundAuth',
    method: 'post',
    data
  })
}
// 重置密码
export function resetPwAPI(data) {
  return http({
    url: '/admin/channel/editPassword',
    method: 'post',
    data
  })
}
// 渠道商品
export function getChannelGoodsListAPI(channelId) {
  return http({
    url: '/admin/channel/goods/list',
    method: 'get',
    params: { channelId }
  })
}
// 提交渠道商品配置
export function submitChannelGoodsConfigAPI(data) {
  return http({
    url: '/admin/channel/goods/config',
    method: 'post',
    data
  })
}

export function getAlipayQrImageAPI(id) {
  return http({
    url: '/admin/channel/alipayQrImage',
    method: 'post',
    data: { id }
  })
}
// 跳转配置列表
export function getConfigListAPI(channelId) {
  return http({
    url: '/admin/channel/page/tree',
    method: 'get',
    params: { channelId }
  })
}
// 提交跳转配置
export function submitConfigListAPI(data) {
  return http({
    url: '/admin/channel/page/config',
    method: 'post',
    data
  })
}
// 商品列表
export function getGoodsListAPI(params) {
  return http({
    url: '/admin/goods/page',
    method: 'get',
    params
  })
}
// 分类树
export function getGoodsCategoryTreeAPI(params) {
  return http({
    url: '/admin/goods/category/tree',
    method: 'get',
    params
  })
}
