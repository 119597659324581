<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="渠道名称">
          <el-input v-model="searchData.channelName" size="small" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="负责人手机号">
          <el-input v-model="searchData.chargeMobile" size="small" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <el-button
      v-checkbtn="isShowBtn(AUTH_BTN.channel_list_add)"
      style="margin-bottom:20px"
      type="primary"
      icon="el-icon-plus"
      size="small"
      @click="$router.push({ name: 'ChannelAdd' })"
      >新增
    </el-button>
    <tp-table
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="pageSize"
    />
    <!-- 二维码弹窗 -->
    <el-dialog :title="`渠道名称：${channelName}`" :visible.sync="dialogVisible" width="700px">
      <el-divider content-position="center">二维码</el-divider>
      <Qrcode ref="code"></Qrcode>
      <div class="code-img">
        <el-button style="margin-top: 10px" type="primary" size="small" @click="copyLink(channelUrl)">复制链接</el-button>
      </div>
    </el-dialog>
    <!-- 重置 -->
    <el-dialog title="重置密码" :visible.sync="dialogVisiblePw" width="700px">
      <el-form ref="form" :model="resetPwData" label-width="80px">
        <el-form-item label="新密码">
          <el-input style="width:300px" v-model="resetPwData.password" size="small" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="" size="small" @click="dialogVisiblePw = false">取消</el-button>
          <el-button type="primary" size="small" @click="savePw">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 支付宝二维码弹窗 -->
    <el-dialog :title="`渠道名称：${channelName}`" :visible.sync="dialogVisibleAlipayQrImage" width="700px">
      <el-divider content-position="center">支付宝二维码</el-divider>
      <div class="code-img">
        <el-image style="width: 200px;" fit="fill" :src="alipayQrImageUrl"></el-image>
        <el-button style="margin-top: 10px" type="primary" size="small" @click="copyLink(alipayHttpUrl)">复制链接</el-button>
      </div>
    </el-dialog>
    <!-- 商品配置 -->
    <el-drawer title="渠道商品配置" :visible.sync="showGoods" direction="rtl" size="35%">
      <el-button style="margin-bottom:10px;margin-left:20px" type="primary" size="mini" @click="submitConfig">保存配置</el-button>
      <el-divider></el-divider>
      <div class="goods-box">
        <el-tree
          ref="tree"
          highlight-current
          :data="goodsList"
          show-checkbox
          node-key="id"
          default-expand-all
          :default-checked-keys="defaultSelect"
          :props="defaultProps"
          @check="checkChange"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span v-if="data.level != 1">{{ data.id }} - </span>
            <span :class="{ bold: data.level == 1 }">{{ node.label }}</span>
          </span>
        </el-tree>
      </div>
    </el-drawer>
    <!-- 跳转配置 -->
    <PageConfig ref="page"></PageConfig>
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import {
  getListAPI,
  deleteChannelAPI,
  changeStatusAPI,
  changeAlipayFundAuthAPI,
  resetPwAPI,
  getChannelGoodsListAPI,
  submitChannelGoodsConfigAPI,
  getAlipayQrImageAPI,
  getConfigListAPI
} from './api'
import Qrcode from '@/components/qrcode/qrcode.vue'
import { getStoreAlipayMaterialAPI } from '@/views/store/store-list/api'
import PageConfig from './pageConfig.vue'
const columns = [
  {
    label: '操作人',
    prop: 'actionUser',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['actionUser']?.['userName']}</p>
    }
  },
  {
    label: '渠道名称',
    prop: 'channelName',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['channelName']}</p>
    }
  },
  {
    label: '渠道代码',
    prop: 'channelCode',
    minWidth: '160',
    customRender(h, row) {
      return <p>{row['channelCode']}</p>
    }
  },
  {
    label: '负责人',
    prop: 'chargeName',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['chargeName']}</p>
    }
  },
  {
    label: '手机号',
    prop: 'chargeMobile',
    minWidth: '130',
    customRender(h, row) {
      return <p>{row['chargeMobile']}</p>
    }
  },
  {
    label: '创建时间',
    prop: 'createTime',
    minWidth: '180',
    customRender(h, row) {
      return <p>{row['createTime']}</p>
    }
  },
  {
    label: '状态',
    prop: 'status',
    minWidth: '120',
    customRender(h, row) {
      return (
        <div>
          <el-switch
            disabled={!this.isShowBtn(this.AUTH_BTN.channel_list_status)}
            class="switch"
            onChange={() => this.changeStatus(row)}
            v-model={row['status']}
            active-value={true}
            inactive-value={false}
            active-text="启用"
            inactive-text="禁用"
          ></el-switch>
        </div>
      )
    }
  },
  {
    label: '免押',
    prop: 'mustAlipayFundAuth',
    minWidth: '120',
    customRender(h, row) {
      return (
          <div>
            <el-switch
                disabled={!this.isShowBtn(this.AUTH_BTN.channel_list_alipay_fund_auth) || !row['status']}
                class="switch"
                onChange={() => this.changeAlipayFundAuth(row)}
                v-model={row['mustAlipayFundAuth']}
                active-value={true}
                inactive-value={false}
                active-text="启用"
                inactive-text="禁用"
            ></el-switch>
          </div>
      )
    }
  },
  {
    label: '商品配置',
    prop: 'goodsConfig',
    minWidth: '100',
    customRender(h, row) {
      return (
        <p>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.channel_list_config) || !row['status']}
            underline={false}
            type="primary"
            onClick={() => this.handleConfig(row)}
          >
            配置
          </el-link>
        </p>
      )
    }
  },
  {
    label: '跳转配置',
    prop: 'goodsConfig',
    minWidth: '100',
    customRender(h, row) {
      return (
        <p>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.channel_list_page) || !row['status']}
            underline={false}
            type="primary"
            onClick={() => this.handleUrlConfig(row)}
          >
            配置
          </el-link>
        </p>
      )
    }
  },
  {
    label: '操作',
    minWidth: '200',
    fixed: 'right',
    customRender(h, row) {
      return (
        <div>
          <el-link
            style="margin-right:10px;"
            disabled={!this.isShowBtn(this.AUTH_BTN.channel_list_reset)}
            underline={false}
            type="primary"
            onClick={() => this.resetPw(row['id'])}
          >
            重置密码
          </el-link>
          <el-link
            style="margin-right:10px;"
            disabled={!this.isShowBtn(this.AUTH_BTN.channel_list_edit)}
            underline={false}
            type="primary"
            onClick={() => this.$router.push({ name: 'ChannelEdit', params: { id: row['id'] } })}
          >
            编辑
          </el-link>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.channel_list_del) || !row['status']}
            style="margin-right:10px;"
            underline={false}
            type="primary"
            onClick={() => this.handleDel(row)}
          >
            删除
          </el-link>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.channel_list_code)}
            style="margin-right:10px;"
            underline={false}
            type="primary"
            onClick={() => this.showCode(row)}
          >
            h5二维码
          </el-link>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.channel_list_alipay_code)}
            underline={false}
            type="primary"
            onClick={() => this.showAlipayCode(row)}
          >
            支付宝二维码
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'List',
  mixins: [authBtnMixin],
  components: { Qrcode, PageConfig },
  data() {
    return {
      dialogVisiblePw: false,
      dialogVisible: false,
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        channelName: '',
        chargeMobile: ''
      },
      channelName: '',
      channelUrl: '',
      // url: '',
      resetPwData: {
        password: '',
        id: ''
      },
      configData: {
        channelId: '',
        excludeGoodsIdList: []
      },
      goodsList: [],
      defaultProps: {
        children: 'goodsList',
        label: 'goodsTitle',
        value: 'id'
      },
      showGoods: false,
      defaultSelect: [],
      alipayQrImageUrl: '',
      alipayHttpUrl: '',
      dialogVisibleAlipayQrImage: false
    }
  },

  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },

  methods: {
    // 保存配置
    submitConfig() {
      console.log(this.configData)
      submitChannelGoodsConfigAPI(this.configData).then(() => {
        this.$message.success('保存成功')
        this.getList()
        this.showGoods = false
      })
    },
    // 渠道商品配置
    async handleConfig({ id }) {
      this.configData = {
        channelId: id,
        excludeGoodsIdList: []
      }
      this.defaultSelect = []
      const res = await getChannelGoodsListAPI(id)
      this.goodsList = res.map(item => {
        return {
          id: item.categoryId,
          goodsTitle: item.categoryName,
          goodsList: item.goodsList,
          level: 1
        }
      })
      this.showGoods = true
      let un_select_ids = [] //每次打开弹窗时，商品列表未选中的id集合
      this.goodsList.forEach(item => {
        item.goodsList.forEach(goods => {
          if (goods.isChannelSelect) {
            this.defaultSelect.push(goods.id) //默认选中的id
          } else {
            un_select_ids.push(goods.id)
          }
        })
      })
      this.$set(this.configData, 'excludeGoodsIdList', un_select_ids) //打开弹窗设置默认值
    },
    // 复选框被点击时的回调
    checkChange() {
      // 获取当前被选中的菜单id数组
      // const res = this.$refs.tree.getCheckedKeys()
      const select_ids = this.$refs.tree.getCheckedNodes(true, true).map(item => item.id)
      console.log('选中：', select_ids)
      let un_select_ids = [] //每次点击复选框时，获取未选中的id
      this.goodsList.forEach(item => {
        item.goodsList.forEach(item2 => {
          if (select_ids.indexOf(item2.id) == -1) {
            un_select_ids.push(item2.id)
          }
        })
      })
      console.log('未选中：', un_select_ids)
      this.$set(this.configData, 'excludeGoodsIdList', un_select_ids)
    },
    async getList() {
      const { channelName, chargeMobile } = this.searchData
      let params = { channelName, chargeMobile, page: this.currentPage, pageSize: this.pageSize }
      const res = await getListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
    },
    // 删除
    handleDel({ id }) {
      this.$confirm('此操作将永久删除该渠道, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteChannelAPI(id).then(() => {
            this.getList()
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 启用/禁用
    changeStatus(row) {
      let { id, status } = row
      let data = { id, status }
      let confirmDesc = status ? '启用' : '禁用'

      this.$confirm(`此操作将${confirmDesc}该渠道, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          row.status = status
          changeStatusAPI(data).then(() => {
            this.getList()
            this.$message.success('操作成功')
          })
        })
        .catch(() => {
          row.status = !status
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    changeAlipayFundAuth(row) {
      let { id, mustAlipayFundAuth } = row
      let data = { id, mustAlipayFundAuth }
      let confirmDesc = mustAlipayFundAuth ? '启用' : '禁用'

      this.$confirm(`此操作该渠道将${confirmDesc}免押, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            row.mustAlipayFundAuth = mustAlipayFundAuth
            changeAlipayFundAuthAPI(data).then(() => {
              this.getList()
              this.$message.success('操作成功')
            })
          })
          .catch(() => {
            row.mustAlipayFundAuth = !mustAlipayFundAuth
            this.$message({
              type: 'info',
              message: '已取消操作'
            })
          })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    showCode(row) {
      const { channelUrl, channelName } = row
      this.dialogVisible = true
      this.channelName = channelName
      this.channelUrl = channelUrl
      this.$nextTick(function() {
        this.$refs.code.QRlink = channelUrl
        this.$refs.code.getQRcode()
      })
    },
    copyLink(url) {
      this.$copyText(url).then(
        () => {
          this.$message.success('复制成功')
        },
        () => {
          this.$message.error('复制失败')
        }
      )
    },
    async showAlipayCode(row) {
      const { channelName, id } = row
      const res = await getAlipayQrImageAPI(id)
      this.channelName = channelName
      this.$nextTick(function() {
        this.alipayQrImageUrl = res.alipayQrImageUrl
        this.alipayHttpUrl = res.alipayHttpUrl
        this.dialogVisibleAlipayQrImage = true
      })
    },
    resetPw(id) {
      this.$set(this.resetPwData, 'id', id)
      this.dialogVisiblePw = true
    },
    savePw() {
      resetPwAPI(this.resetPwData).then(() => {
        this.dialogVisiblePw = false
        this.$message.success('重置成功')
        this.getList()
      })
    },
    // 跳转配置
    async handleUrlConfig({ id }) {
      this.$refs.page.show = true
      this.$refs.page.init()
      this.$refs.page.channelId = id
      this.$refs.page.getConfigList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .bold {
    font-weight: 600;
    font-size: 17px;
  }
  .el-divider--horizontal {
    margin: 0 0 20px;
  }

  .el-form {
    .el-form-item {
      margin-right: 35px;
    }
  }
  .tp-table {
    .el-table__fixed-right {
      height: 100% !important;
    }
  }
  .el-drawer {
    .el-drawer__header {
      font-size: 18px;
      font-weight: 600;
    }
    .goods-box {
      padding: 0 20px;
    }
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  .el-dialog__body {
    padding: 20px 20px 50px 20px;
  }
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
    margin-right: 10px;
  }

  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }

  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
  .code-img {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
</style>
