<template>
  <el-drawer class="drawer-box" title="跳转配置" :visible.sync="show" size="55%" direction="rtl">
    <div class="top">
      <el-button type="primary" size="mini" @click="submit">保存配置</el-button>
    </div>
    <!-- <el-divider></el-divider> -->
    <div class="content">
      <div class="left">
        <el-menu :default-active="defaultActive" :default-openeds="opends" class="el-menu-vertical-demo">
          <el-submenu :index="item.id + ''" v-for="(item, i) in leftList" :key="i">
            <span slot="title">{{ item.pageName }}</span>
            <el-menu-item :index="sub.id + ''" v-for="(sub, index) in item.children" :key="index" @click="clickPageName(sub)">{{
              sub.pageName
            }}</el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
      <div class="line"></div>
      <div class="right">
        <admin-title v-if="currentPage.pageName" :title="currentPage.pageName" bottom="10px"></admin-title>
        <div v-if="!dsCode" class="ds">
          <el-radio-group v-model="rightPageId">
            <el-radio v-for="(item, i) in currentPage.children" :key="i" :label="item.id">{{ item.pageName }}</el-radio>
          </el-radio-group>
        </div>
        <!-- 商品列表 -->
        <div v-if="dsCode == 'goodsList'" class="goods-list">
          <div class="search">
            <el-input style="width:200px" v-model="goodsSearchData.id" placeholder="请输入商品ID" size="mini"></el-input>
            <el-button style="margin-left: 10px" type="primary" size="mini" @click="searchGoodsList">搜索</el-button>
            <el-button type="info" size="mini" @click="resetGoodsList">重置</el-button>
          </div>
          <el-table :data="goodsList">
            <el-table-column prop="" label="" width="80">
              <template slot-scope="{ row }">
                <el-radio v-model="pageQueryId" :label="row.id">{{ '' }}</el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="id" label="商品ID" min-width="100"> </el-table-column>
            <el-table-column prop="goodsTitle" label="商品名称" min-width="100"></el-table-column>
            <el-table-column prop="id" label="商品分类" min-width="100">
              <template slot-scope="{ row }">
                {{ row.category && row.category.categoryName }}
              </template>
            </el-table-column>
            <el-table-column prop="day" label="商品图片" min-width="100" fixed="right">
              <template slot-scope="{ row }">
                <el-image
                  style="width: 60px; height: 60px; display: block"
                  :src="row.coverImageUrl"
                  :preview-src-list="[row.coverImageUrl]"
                  fit="cover"
                ></el-image>
              </template>
            </el-table-column>
          </el-table>
          <pagination
            :total="goodsTotal"
            :page.sync="goodsSearchData.page"
            :limit.sync="goodsSearchData.pageSize"
            @pagination="getGoodsList"
          />
        </div>
        <!-- 商品分类 -->
        <div v-if="dsCode == 'goodsCategoryList'" class="goods-category">
          <el-table
            style="width:100%"
            :data="goodsCategoryList"
            row-key="id"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            :header-row-style="{ height: '50px', color: '#626e7c' }"
            :header-cell-style="{ background: '#f8f9fb', padding: '8px 0' }"
            :cell-style="changeTr"
            :indent="30"
            default-expand-all
          >
            <el-table-column label="" align="left" min-width="80px">
              <template slot-scope="{ row }">
                <div>
                  <el-radio v-model="pageQueryId" :label="row.id">{{ '' }}</el-radio>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="分类ID" align="left" min-width="100px">
              <template slot-scope="{ row }">
                <span>{{ row.id }} </span>
              </template>
            </el-table-column>
            <el-table-column label="商品分类" align="left" min-width="120px">
              <template slot-scope="{ row }">
                {{ row.categoryName }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { getConfigListAPI, getGoodsListAPI, getGoodsCategoryTreeAPI, submitConfigListAPI } from './api'
export default {
  name: 'PageConfig',

  data() {
    return {
      show: false,
      leftList: [],
      channelId: '',
      pageId: '',
      rightPageId: '',
      dsCode: '',
      currentPage: {},
      opends: [],
      defaultActive: '',
      goodsSearchData: {
        page: 1,
        pageSize: 10,
        id: '',
        channelId: '',
        status: 1,
      },
      goodsList: [],
      goodsTotal: 0,
      pageQueryId: '',
      goodsCategoryList: []
    }
  },
  computed: {},
  watch: {},
  mounted() {},

  methods: {
    submit() {
      let data = {
        channelId: this.channelId,
        pageConfig: {
          pageId: this.dsCode ? this.pageId : this.rightPageId,
          pageQueryId: this.dsCode ? this.pageQueryId : ''
        }
      }
      console.log(data)
      submitConfigListAPI(data).then(() => {
        this.$message.success('保存成功')
        this.show = false
      })
    },
    clickPageName(sub) {
      if (this.pageId == sub.id) return
      this.pageQueryId = ''
      this.rightPageId = ''
      this.pageId = sub.id
      this.dsCode = sub.dsCode
      this.currentPage = sub
      if (this.dsCode == 'goodsList') {
        this.getGoodsList()
      }
      if (this.dsCode == 'goodsCategoryList') {
        this.getGoodsCategoryTree()
      }
    },
    async getConfigList() {
      const res = await getConfigListAPI(this.channelId)
      this.leftList = res.pageList
      this.opends = this.flattenTree(this.leftList).map(item => String(item.id))
      const { pageId, pageQueryId, pageQuery, dsCode } = res.pageConfig
      this.dsCode = dsCode
      if (dsCode) {
        // 商品   分类
        if (dsCode == 'goodsCategoryList') {
          await this.getGoodsCategoryTree()
        } else {
          await this.getGoodsList()
        }
        this.pageId = pageId
        this.defaultActive = pageId + ''
        this.pageQueryId = pageQueryId
      } else {
        //商城链接
        const parentId = this.findElementById(this.leftList, pageId).parentId
        this.currentPage = this.findElementById(this.leftList, parentId)
        this.pageId = parentId
        this.rightPageId = pageId
        this.defaultActive = parentId + ''
      }
    },
    flattenTree(tree) {
      let flattenedArray = []
      tree.forEach(node => {
        flattenedArray.push(node)
        if (node.children && node.children.length > 0) {
          flattenedArray = flattenedArray.concat(this.flattenTree(node.children))
        }
      })
      return flattenedArray
    },
    async getGoodsList() {
      this.goodsSearchData.channelId = this.channelId
      const res = await getGoodsListAPI(this.goodsSearchData)
      this.goodsList = res.list
      this.goodsTotal = res.total
    },
    searchGoodsList() {
      this.goodsSearchData.page = 1
      this.getGoodsList()
    },
    resetGoodsList() {
      this.goodsSearchData = {
        page: 1,
        pageSize: 10,
        id: '',
        channelId: this.channelId,
        status: 1,
      }
      this.getGoodsList()
    },
    async getGoodsCategoryTree() {
      this.goodsCategoryList = await getGoodsCategoryTreeAPI({status: 1})
    },
    // 修改第一列无children的样式
    changeTr({ row, column, rowIndex, columnIndex }) {
      if (!row.children && columnIndex == 0) {
        return { paddingLeft: '23px' }
      }
    },
    init() {
      this.leftList = []
      this.channelId = ''
      this.pageId = ''
      this.rightPageId = ''
      this.dsCode = ''
      this.currentPage = {}
      ;(this.opends = []), (this.defaultActive = '')
      this.goodsSearchData = {
        page: 1,
        pageSize: 10,
        id: '',
        channelId: '',
        status: 1,
      }
      this.goodsList = []
      this.goodsTotal = 0
      this.pageQueryId = ''
      this.goodsCategoryList = []
    },
    findElementById(tree, targetId) {
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i]
        if (node.id === targetId) {
          return node
        }
        if (node.children && node.children.length > 0) {
          const found = this.findElementById(node.children, targetId)
          if (found) {
            return found
          }
        }
      }
      return null
    }
  }
}
</script>
<style lang="scss" scoped>
.drawer-box {
  .top {
    padding: 0 20px;
    padding-bottom: 20px;
  }
  .content {
    display: flex;
    padding: 0 20px;
    padding-bottom: 20px;
    // height: calc(100vh - 135px);
    .left /deep/ {
      width: 200px;
      .el-menu {
        border: none;
      }
    }
    .line {
      width: 1px;
      height: 100%;
      background-color: #f5f5f5;
    }
    .right {
      flex: 1;
      padding: 0 20px;
      min-height: calc(100vh - 155px);
      border-left: 1px solid #f5f5f5;
      .title {
        font-weight: 600;
        font-size: 14px;
      }
      .goods-list /deep/ {
        .title {
          margin-bottom: 0;
        }
        .search {
          padding-bottom: 20px;
        }
      }
      .goods-category /deep/ {
        width: 100%;
        .el-table {
          .cell {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}
</style>
